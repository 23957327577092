import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import logo from '../images/spinning-logo.gif';

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    
    <img className="logo" alt="Figure 8 International logo" width="300" height="125" src={logo} />
  </Layout>
)

export default IndexPage
/*
<StaticImage
      src="../images/full-logo.svg"
      quality={100}
      loading="eager"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Figure 8 Intl logo"
      style={{ marginBottom: `1.45rem` }}
    />
    */